// ** React Imports
import { memo, useContext } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import loadable from "@loadable/component";
import withHydrationOnDemand from "react-hydration-on-demand";

// ** Custom Components
import Heading from "components/typography/Heading";
import Paragraph from "components/typography/Paragraph";
import Block from "components/layout/Block";
import Text from "components/typography/Text";

// ** Contexts
import DeviceTypeContext from "contexts/deviceTypeContext";
const WelcomeSlider = loadable(() => import("./WelcomeSlider"), {
	ssr: false,
});
const WelcomeSliderWithHydrationOnDemand = withHydrationOnDemand({
	on: ["idle"],
	onBefore: WelcomeSlider.load,
})(WelcomeSlider);

// #####################################################

const Welcome = ({ centerScreenshot }) => {
	const { t } = useTranslation(["landing"]);

	const { isDesktop } = useContext(DeviceTypeContext);

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);

	// #####################################################

	return (
		<Block flex column fullWidth>
			<Block pr={isDesktop ? 5 : 0} pt={isDesktop ? 10 : 0}>
				<Text color={isDesktop ? "whiteAlways" : undefined}>
					<Heading bold type="h1" size="2rem" textCenter={!isDesktop}>
						{t("landing:header")}
					</Heading>
					<Paragraph size="1.5rem" line="1.5rem" bold>
						{t("landing:subheader")}
					</Paragraph>
					<Paragraph size="1.5rem" line="1.5rem" bold mb={10}>
						{t("landing:desc")}
					</Paragraph>
				</Text>
			</Block>
			<Block
				fullWidth
				justifyCenter
				alignCenter
				textCenter={centerScreenshot}
			>
				<WelcomeSliderWithHydrationOnDemand />
			</Block>
		</Block>
	);
};

// #####################################################

export default memo(Welcome);
